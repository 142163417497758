<template>
  <div class="animated fadeIn">
    <b-form @submit.prevent="onSubmit">
      <b-row class="mb-4">
        <b-col cols="12" md="6">
          <b-card title="Revenue Report">
            <div class="clearfix">

              <!-- date pick -->
              <vue-monthly-picker
                class="float-left"
                v-model="reportMonth"
                :max="$moment().subtract(1, 'M')"
              ></vue-monthly-picker>

              <!-- vie invoices -->
              <router-link class="float-right" :to="{ name: 'invoiceList', query: {
                type: invoicesBy,
                mo: reptMo,
                yr: reptYr
              } }" target="_blank">View Invoices</router-link>

            </div>
            <hr />
            <h5 class="mb-3">
              Invoices Included: {{ invoicesCounted.length }}
              <template v-if="invoicesOutstanding.length">
                <br />
                <small class="text-danger">Invoices Outstanding (unpaid): {{ invoicesOutstanding.length }}</small>
              </template>
            </h5>
            <!-- rept fields -->
            <div v-if="reportExists" class="bg-warning">
              <p class="p-4">Please select a month to report using the field above.</p>
            </div>
            <div v-else-if="!invoicesLoaded" class="bg-info">
              <p class="p-4">Just a moment while we gather invoices...</p>
            </div>
            <div v-else-if="reportSubmitted" class="bg-success">
              <p class="p-4">Submitting your report...</p>
            </div>
            <ReportFormSimple
              v-else
              class="mb-4" 
              :formFields="form" 
              :totalGroups="totalGroups" 
              @form-update="onUpdate"
            />
            <!-- total tax collected -->
            <template v-if="taxCollected">
              <hr />
              <h5 class="mb-3">Tax Collected: ${{ taxCollected.toFixed(2) }}</h5>
              <p>
                Tax collected is calculated by adding the tax amount from each invoice.
              </p>
            </template>
          </b-card>
        </b-col>
        <b-col>
          <p>
            Your royalties due are based on the revenue earned during the month by your N&nbsp;Zone&nbsp;Sports&trade; 
            branch and are due on the 1st of each month. Royalties submitted after the 10th of the month will be subject to late fees. 
            Your royalties due are calculated as (revenue x {{ royaltyPercentage | toPercent }}). Your minimum royalties due is ${{ royaltyMinimum }}.
          </p>
          <!-- div class="my-4">
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>Date</th>
                  <th>Amnt</th>
                  <th>Items</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(inv, i) in invoicesCounted" :key="i">
                  <td>{{ (i += 1) }}</td>
                  <td>{{ inv.id }}</td>
                  <td>{{ $moment(inv.dateCreated).format('MM/DD') }}</td>
                  <td>{{ inv.amountDue }}</td>
                  <td>{{ inv.items.length }}</td>
                </tr>
              </tbody>
            </table>
            Total Invoices Amount Due: ${{ totalInvAmntDue }}
          </div -->
          <template v-if="invoicesLoaded">
            <h4>
              Royalties Due: ${{ royaltyAmount }}
            </h4>
            <b-form-textarea
            v-model="comments"
            placeholder="Comments"
            class="mb-3"
            ></b-form-textarea>
            <b-btn type="submit" variant="success" block>Submit</b-btn>
          </template>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import VueMonthlyPicker from 'vue-monthly-picker'
import ReportFormSimple from '../../components/ReportFormSimple'
import reportsTpl from './revenue-fields' // @TODO: pull from admin settings
// import { constants } from "@/shared/constants"

const DEFAULTS = {
  nzone_jr_clients: 0,
  nzone_jr_sales: 0,
  nzone_clients: 0,
  nzone_sales: 0,
  nzone_adult_clients: 0,
  nzone_adult_sales: 0,
  nzone_camp_clients: 0,
  nzone_camp_sales: 0,
  nzone_comm_clients: 0,
  nzone_comm_sales: 0,
  additional_sales: 0,
  misc_clients: 0,
  misc_sales: 0,
  deductions: 0
}

export default {
  data () {
    return {
      form: reportsTpl.revenueFields,
      totalGroups: reportsTpl.totalGroups,
      reportMonth: this.$moment().subtract(1, 'M').startOf('month'),
      revenueTotal: 0,
      comments: '',
      reportExists: false,
      invoicesLoaded: false,
      invoicesCounted: [],
      taxCollected: 0,
      invoicesOutstanding: [],
      reportSubmitted: false,
      invoicesBy: 'datePaid'
    }
  },
  computed : {
    ...mapGetters([
      'business',
      'invoices'
    ]),
    totalInvAmntDue () {
      return Object.values(this.invoicesCounted).reduce((t, { amountDue }) => t + amountDue, 0)
    },
    reptMo () {
      return this.$moment(this.reportMonth._d).format('M')
    },
    reptYr () {
      return this.$moment(this.reportMonth._d).format('YYYY')
    },
    royaltyPercentage () {
      return parseFloat(this.business.royaltyPercentage)
    },
    royaltyMinimum () {
      return parseFloat(this.business.royaltyMinimum)
    },
    royaltyAmount () {
      let res = this.revenueTotal * this.royaltyPercentage
      if (res < this.royaltyMinimum) {
        res = this.royaltyMinimum
      }
      return res.toFixed(2)
    }
  },
  methods: {
    ...mapActions([
      'getInvoices',
      'getReportsRevenue',
      'postReportsRevenue'
    ]),
    async doGetInvoices () {
      const paramAfter = this.invoicesBy + '[after]'
      const paramBefore = this.invoicesBy + '[strictly_before]'
      let query = {
        business: this.business.id,
        pagination: false
      }
      query[paramAfter] = this.$moment(this.reportMonth._d).format()
      query[paramBefore] = this.$moment(this.reportMonth._d).add(1, 'M').format()
      await this.getInvoices(query)
    },
    checkSubmissions () {
      let params = {
        reptMo: this.reptMo,
        reptYr: this.reptYr
      }
      if (this.currentBusiness.id) {
        params.business = this.currentBusiness.id
      }
      return this.getReportsRevenue(params).then(resp => {
        if (resp.length) {
          this.reportExists = true
          this.showError({ message: 'You have already submitted your revenue report for the selected month.' })
        } else {
          this.reportExists = false
        }
      })
    },
    addSales (defaults, registration, amount) {
      let addTo
      if (registration) {
        switch (registration.event.type.id) {
          case 1: addTo = 'nzone_jr'; break
          case 2: addTo = 'nzone'; break
          case 3: addTo = 'nzone_adult'; break
          case 4: addTo = 'nzone_camp'; break
          case 5: addTo = 'nzone_comm'; break
          case 6: addTo = 'misc'; break
          default: addTo = 'additional'
          // default: throw new TypeError("Invalid registration type ", registration.event.type.id)
        }
        defaults[addTo + '_clients']++
      }
      defaults[addTo + '_sales'] += parseFloat(amount)
      return defaults
    },
    async setDefaults () {
      // get fields
      // let fields = {}
      // let registrations = {}
      // let amountDue = 0
      let defaults = { ...DEFAULTS }
      await this.doGetInvoices()
      for (let i1 in this.invoices) {
        if (!this.invoices[i1].items.length) continue  // disregard invoices with no items
         let thisInvoice = this.invoices[i1]
          // separate invoices with no transaction id
          if (!thisInvoice.transactionId) {
            this.invoicesOutstanding.push(thisInvoice)
            continue
          }
          // update tax collected
          this.taxCollected += (thisInvoice.tax || 0)
          if (thisInvoice.tax) {
            // subtract tax from amount paid (does not count towards revenue)
            thisInvoice.amountDue -= thisInvoice.tax
          }
          // because of the discount code, 
          // only the cost of the first item in the invoice is counted
          // and the amount due is divided by the number of items
          // const numItems = thisInvoice.items.length
          // update defaults
          for (let i2 in thisInvoice.items) {
            let item = thisInvoice.items[i2]
            defaults = this.addSales(defaults, item.registration, (thisInvoice.amountDue / thisInvoice.items.length))
          }
          this.invoicesCounted.push(thisInvoice)
      }
      for (let i in this.form) {
        for (let key in defaults) {
          if (this.form[i].name === key) {
            this.form[i].value = defaults[key]
          }
        }
      }

      this.invoicesLoaded = true
    },
    onUpdate (data) {
      this.revenueTotal = data.Revenue
    },
    onSubmit () {
      this.$bvModal.msgBoxConfirm('You are about to submit this revenue report. By submitting, you are confirming '
        +'that the data your are submitting was reviewed by you and is accurate. Once it is submitted, it cannot be modified. '
        +'Please click OK to confrim this action or click Cancel to continue editing.')
      .then(conf => {
        if (conf) {
          this.reportSubmitted = true
          this.postReportsRevenue({
            business: this.business["@id"],
            reptYr: parseInt(this.reptYr),
            reptMo: parseInt(this.reptMo),
            totalRevenue: this.revenueTotal,
            royalties: parseFloat(this.royaltyAmount),
            comments: this.comments,
            data: this.form,
            user: this.currentUser["@id"]
          }).then(resp => {
            this.$router.push({ name: 'reportsRevenueDetails', params: { rptId: resp.id } })
            this.showSuccess({ message: 'Thank you for submitting your report!' })
          })
        }
      })
    }
  },
  watch: {
    reportMonth () {
      this.reportExists = false
      this.invoicesLoaded = false
      this.reportSubmitted = false
      this.checkSubmissions().then(submitted => {
        if (!submitted) {
          this.setDefaults()
        }
      })
    }
  },
  components: {
    ReportFormSimple,
    VueMonthlyPicker
  },
  mounted () {
    this.checkSubmissions().then(submitted => {
      if (!submitted) {
        this.setDefaults()
      }
    })
  }
}
</script>
